import controlsIcon from '../../icons/controls.svg?raw';
import mutedIcon from '../../icons/muted.svg?raw';
import unmutedIcon from '../../icons/unmuted.svg?raw';
import { createElement } from '../utils/dom';

const playSvg =
	'<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 20"><path d="M0 20V0l18 10L0 20Z" /></svg><span class="visually-hidden">play video</span>';
const pauseSvg =
	'<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 20"><path d="M4 20H0V0h4v20Zm6-20H6v20h4V0Z"/></svg><span class="visually-hidden">pause video</span>';

const PAUSED_CLASS = 'is-paused';
const PLAYING_CLASS = 'is-playing';
const MUTED_CLASS = 'is-muted';
const UNMUTED_CLASS = 'is-unmuted';

/**
 * @param {HTMLElement} element
 */
export function VideoPlayButton(element) {
	let video = document.getElementById(element.dataset.controls);

	if (!(video instanceof HTMLVideoElement)) {
		return;
	}

	let reducedMotionNoPref = window.matchMedia(
		'(prefers-reduced-motion: no-preference)',
	);

	//dont autoplay on people who prefers-reduced-motion
	if (reducedMotionNoPref.matches) {
		play();
	} else {
		checkStatus(); //set icon if not autoplay
	}

	checkStatus();
	element.addEventListener('click', togglePlay);
	video.addEventListener('play', checkStatus);
	video.addEventListener('pause', checkStatus);

	function play() {
		try {
			video?.play().then(() => checkStatus());
		} catch (error) {
			checkStatus();
		}
	}

	function checkStatus() {
		let playing = isPlaying();

		element.classList.toggle(PAUSED_CLASS, !playing);
		element.classList.toggle(PLAYING_CLASS, playing);
		element.innerHTML = playing ? pauseSvg : playSvg;
	}

	function togglePlay() {
		!isPlaying() ? play() : video?.pause();
	}

	function isPlaying() {
		// It is advised to check if video?.currentTime > 0 however that returns false
		// if the video has just started which in our case means that when we trigger
		// .play and then call checkStatus we get false.

		return !video?.ended && !video?.paused && video?.readyState > 2;
	}
}

/**
 * @param {HTMLElement} element
 */
export function VideoSoundButton(element) {
	let video = document.getElementById(element.dataset.controls);

	if (!(video instanceof HTMLVideoElement)) {
		return;
	}

	checkStatus();
	element.addEventListener('click', toggleSound);

	function checkStatus() {
		let label = createElement('span', {
			class: 'visually-hidden',
		});

		element.classList.toggle(MUTED_CLASS, video.muted);
		element.classList.toggle(UNMUTED_CLASS, !video.muted);

		if (video.muted) {
			element.innerHTML = mutedIcon;
			label.textContent = 'Unmute';
		} else {
			element.innerHTML = unmutedIcon;
			label.textContent = 'Mute';
		}

		element.append(label);
	}

	function toggleSound() {
		video.muted = !video.muted;
		checkStatus();
	}
}

export function VideoControlsButton(element) {
	let video = document.getElementById(element.dataset.controls);

	if (!(video instanceof HTMLVideoElement)) {
		return;
	}

	element.addEventListener('click', function () {
		element.innerHTML = controlsIcon;

		let label = createElement('span', {
			class: 'visually-hidden',
		});

		if (video.controls) {
			video.controls = false;
			label.textContent = 'Show controls';
		} else {
			video.controls = true;
			label.textContent = 'Hide controls';
		}

		element.append(label);
	});
}
