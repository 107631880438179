import { Alert } from './modules/alert';
import { App } from './app';
import { Carousel } from './modules/carousel';
import { Disclosure } from './modules/disclosure';
import { Drawer } from './modules/drawer';
import { LazyLoader } from './modules/lazy-loader';
import { NavigationBar } from './modules/navigation-bar';
import { Video } from './modules/video';
import {
	VideoPlayButton,
	VideoSoundButton,
	VideoControlsButton,
} from './modules/video-control';
import { init as CookieConsent } from './modules/cookie-consent';

let app = App();

CookieConsent();

let lazyLoader = LazyLoader();
lazyLoader.update();

app.start();
app.register('drawer', Drawer);
app.register('disclosure', Disclosure);
app.register('alert', Alert);
app.register('navigation-bar', NavigationBar);
app.register('video', Video);
app.register('carousel', Carousel);
app.register('video-play-button', VideoPlayButton);
app.register('video-sound-button', VideoSoundButton);
app.register('video-controls-button', VideoControlsButton);
