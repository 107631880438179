/**
 * @param {string} name
 * @param {HTMLFormElement} form
 * @return {string[]|string|*}
 */
export function getInputValue(name, form) {
	let value;
	// @ts-ignore https://github.com/microsoft/TypeScript/issues/19437
	let field = form.elements[name];

	if (field === undefined) {
		return value;
	}

	if (isRadioNodeListLike(field)) {
		let elements = Array.from(field);
		let isRadioGroup = elements.every((el) => el.type === 'radio');
		let values = elements.filter((el) => el.checked).map((el) => el.value);

		if (!isRadioGroup) {
			value = values;
		} else {
			value = values.length ? values[0] : '';
		}

		return value;
	}

	if (field instanceof HTMLSelectElement) {
		let options = Array.from(field.options);
		let isSingleSelect = field.type === 'select-one';

		if (isSingleSelect) {
			value = options[field.selectedIndex].value;
		} else {
			value = options.filter((opt) => opt.selected).map((opt) => opt.value);
		}

		return value;
	}

	if (field instanceof HTMLInputElement) {
		if (field.type === 'checkbox') {
			value = field.checked ? field.value : '';
		} else {
			value = field.value;
		}

		return value;
	}

	return value;
}

/**
 * Check a value to see if it's an object representing a collection of checkbox or
 * radio elements. Most browsers correctly report RadioNodeList for both however
 * IE returns a HTMLCollection despite supporting RadioNodeList.
 *
 * @param {*} element
 * @return {boolean}
 */
function isRadioNodeListLike(element) {
	return (
		element instanceof HTMLCollection ||
		(typeof RadioNodeList !== 'undefined' && element instanceof RadioNodeList)
	);
}
