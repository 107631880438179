/**
 * -----------------------------------------------------------------------------
 * Blocking
 * -----------------------------------------------------------------------------
 *
 * In order to defer the loading and execution of an element use the
 * data-cookie-consent attribute.
 *
 * For inline scripts the type needs to be set to text/plain.
 *
 * <script data-cookie-consent type="text/plain">
 * 	// script contents
 * </script>
 *
 * For external scrips and iframes etc. set the data-cookie-consent value to
 * the url of the resource.
 *
 * <script data-cookie-consent="https://www.exmaple.com/script.js">
 * <iframe data-cookie-consent="https://www.exmaple.com/script.js"></iframe>
 *
 * -----------------------------------------------------------------------------
 * Consent management
 * -----------------------------------------------------------------------------
 *
 * Consent can be managed in two ways, using a button with the value set to
 * 0 or 1 or using a form with radio buttons whose values are set to 0 or 1.
 *
 * <button class="js-cookie-consent-button" type="button" value="1">Allow</button>
 * <button class="js-cookie-consent-button" type="button" value="0">Disallow</button>
 *
 * <form class="js-cookie-consent-form">
 *	  <input id="cookie-consent-allow" type="radio" name="cookie-preference" value="1">
 *	  <label for="cookie-consent-allow">Allow</label>
 *
 * 	  <input id="cookie-consent-disallow" type="radio" name="cookie-preference" value="0">
 * 	  <label for="cookie-consent-disallow">Disallow</label>
 *
 * 	  <button>Save</button>
 *	</form>
 */

import Cookies from 'js-cookie';
import { getInputValue } from '../utils/forms';

/** @type {boolean} */
let consented;
let cookieName = 'cookie-consent';
let attributeName = 'data-cookie-consent';
let formInputName = 'cookie-preference';

export function init() {
	consented = getConsentSettings();

	if (consented) {
		loadAll();
	}

	Array.from(document.querySelectorAll('form.js-cookie-consent-form')).forEach(
		(form) => Form(form),
	);

	Array.from(
		document.querySelectorAll('button.js-cookie-consent-button'),
	).forEach((button) => Button(button));
}

function getConsentSettings() {
	let value = Cookies.get(cookieName);

	if (value === undefined) {
		return undefined;
	}

	return JSON.parse(value);
}

/**
 * @param {boolean} value
 * @param {boolean} load
 */
function setConsent(value, load = true) {
	Notice().hide();

	Cookies.set(cookieName, JSON.stringify(value), {
		expires: 365,
		sameSite: 'strict',
	});

	/* global dataLayer */
	if (value && typeof dataLayer !== 'undefined') {
		dataLayer.push({ event: 'cookie_consent_given' });
	}

	if (value && load) {
		loadAll();
	}
}

function getElements() {
	return Array.from(document.querySelectorAll(`[${attributeName}]`));
}

/**
 * @param {Element|Element[]} element
 */
function load(element) {
	if (Array.isArray(element)) {
		element.forEach((element) => load(element));
		return;
	}

	let attributeValue = element.getAttribute(attributeName);

	if (isInlineScript(element)) {
		loadInlineScript(element);
	} else if (attributeValue) {
		element.removeAttribute(attributeName);
		element.setAttribute('src', attributeValue);
	}
}

function loadAll() {
	load(getElements());
}

/**
 * @param {Element} element
 */
function isInlineScript(element) {
	let attributeValue = element.getAttribute(attributeName);

	return element instanceof HTMLScriptElement && !attributeValue;
}

/**
 * @param {HTMLScriptElement} script
 */
function loadInlineScript(script) {
	/** @type {HTMLScriptElement} clone */
	let clone = script.cloneNode(true);
	clone.setAttribute('type', 'text/javascript');
	clone.removeAttribute(attributeName);
	script.after(clone);
	script.remove();
}

function Notice() {
	let element = document.querySelector('.js-cookie-notice');

	function hide() {
		element.style.display = 'none';
	}

	function show() {
		element.style.display = 'block';
	}

	return { show, hide };
}

/**
 * @param {HTMLButtonElement} element
 */
function Button(element) {
	element.addEventListener('click', onClick);

	/**
	 * @param {MouseEvent} event
	 */
	function onClick(event) {
		/** @type {HTMLButtonElement} */
		let target = event.currentTarget;

		setConsent(!!Number(target.value));
	}
}

/**
 * @param {HTMLFormElement} element
 */
function Form(element) {
	element.addEventListener('submit', onSubmit);

	/**
	 * @param {SubmitEvent} event
	 */
	function onSubmit(event) {
		let value = Number(getInputValue(formInputName, element));

		setConsent(!!value, false);

		event.preventDefault();
	}
}
